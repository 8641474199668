import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import parse from 'html-react-parser';


export default function BlogCategory() {
  return (
    <StaticQuery query={graphql`
        query {
            swapi {
              categories (first: 10) {
                nodes {
                  name 
                  posts(first: 1) {
                    nodes {
                      date
                      uri
                      title
                      featuredImage {
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
`}
      render={data => (
        data.swapi.categories.nodes.map(cat => {
          return (
            <>
              <span className="blog-recent">
                {cat.name}
              </span>


              {
                cat.posts.nodes.map(post => {
                  var today = new Date(post.date);
                  var dd = String(today.getDate()).padStart(2, '0');
                  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                  var yyyy = today.getFullYear();
                  return (
                    <div className="row mt-3 mb-3">
                      <div className="col-4">
                        <Link className="blog-title" aria-current="page" to={post.uri}>
                          <img style={{ height: "50%", width: "100%", borderRadius: "5px" }} loading={"lazy"} className="img-fluid" width="73" height="73" src={post.featuredImage.sourceUrl} alt={post.title} />
                        </Link>
                      </div>
                      <div className="col-8">
                        <Link className="blog-title" aria-current="page" to={post.uri}>
                          <span>
                            {parse(post.title)}
                          </span>
                        </Link>
                        <br />
                        <span>
                          {dd + '/' + mm + '/' + yyyy}
                        </span>
                      </div>
                    </div>
                  )
                })
              }
            </>
          )
        })

      )


      }
    />


  )
}